import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  const [products, setProducts] = useState([]);
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [quantity, setQuantity] = useState('');
  const [sales, setSales] = useState([]);
  const [saleProduct, setSaleProduct] = useState('');
  const [saleQuantity, setSaleQuantity] = useState('');

  useEffect(() => {
    axios.get('/api/products')
      .then(response => setProducts(response.data))
      .catch(error => console.error('Error fetching products:', error));
  }, []);

  useEffect(() => {
    axios.get('/api/sales')
      .then(response => setSales(response.data))
      .catch(error => console.error('Error fetching sales:', error));
  }, []);

  const addProduct = () => {
    axios.post('/api/products', { name, price, quantity })
      .then(response => setProducts([...products, response.data]))
      .catch(error => console.error('Error adding product:', error));
  };

  const recordSale = () => {
    axios.post('/api/sales', { product: saleProduct, quantity: saleQuantity })
      .then(response => setSales([...sales, response.data]))
      .catch(error => console.error('Error recording sale:', error));
  };

  return (
    <div className="App">
      <header>
        <h1>Inventory Management</h1>
      </header>
      <nav>
        <button onClick={() => document.getElementById('productForm').scrollIntoView()}>Add Product</button>
        <button onClick={() => document.getElementById('salesForm').scrollIntoView()}>Record Sale</button>
        <button onClick={() => document.getElementById('inventory').scrollIntoView()}>View Inventory</button>
        <button onClick={() => document.getElementById('sales').scrollIntoView()}>View Sales</button>
      </nav>
      <section id="productForm">
        <h2>Add Product</h2>
        <input placeholder="Name" value={name} onChange={e => setName(e.target.value)} />
        <input placeholder="Price" value={price} onChange={e => setPrice(e.target.value)} />
        <input placeholder="Quantity" value={quantity} onChange={e => setQuantity(e.target.value)} />
        <button onClick={addProduct}>Add Product</button>
      </section>
      <section id="salesForm">
        <h2>Record Sale</h2>
        <input placeholder="Product" value={saleProduct} onChange={e => setSaleProduct(e.target.value)} />
        <input placeholder="Quantity" value={saleQuantity} onChange={e => setSaleQuantity(e.target.value)} />
        <button onClick={recordSale}>Record Sale</button>
      </section>
      <section id="inventory">
        <h2>Inventory</h2>
        <ul>
          {products.map(product => (
            <li key={product._id}>{product.name} - {product.price} - {product.quantity}</li>
          ))}
        </ul>
      </section>
      <section id="sales">
        <h2>Sales</h2>
        <ul>
          {sales.map(sale => (
            <li key={sale._id}>{sale.product} - {sale.quantity}</li>
          ))}
        </ul>
      </section>
    </div>
  );
}

export default App;
